import * as Sentry from "@sentry/react";
import { getIsPreview } from "../../../core/previewMode";

// logger may actually use console
/* eslint-disable no-console */

type Data = Record<string, unknown> & { exception?: unknown };

// too many requests on sentry will cause a http status "429 Too many requests"
// the limit is chosen arbitrarily
const MESSAGE_LOG_LIMIT = 5;
const logMessageMap: Map<string, number> = new Map();

const updateLogMessageMapFor = (message: string) => {
  const mapEntry = logMessageMap.get(message) ?? 0;
  if (mapEntry > MESSAGE_LOG_LIMIT) {
    console.log(`Suppressing log message: ${message}`);
    return false;
  }
  logMessageMap.set(message, mapEntry + 1);
  return true;
};

const setExtrasIfAny = (scope: Sentry.Scope, ...extras: (Data | undefined)[]) => {
  const extrasWithPreviewMode = [...extras, extraIfPreviewMode()];
  const flatExtras = extrasWithPreviewMode.reduce((acc: Data, extra = {}) => ({ ...acc, ...extra }), {});

  if (Object.keys(flatExtras).length > 0) {
    scope.setExtras(flatExtras);
  }
};

const extraIfPreviewMode = (): { isPreviewMode?: boolean } => {
  return getIsPreview() ? { isPreviewMode: true } : {};
};

export const logger = {
  error: (message: string, data?: Data) => {
    console.error(message, data);
    const shallCapture = updateLogMessageMapFor(message);
    if (!shallCapture) {
      return;
    }
    if (data?.exception && data?.exception instanceof Error) {
      const { exception, ...properties } = data;
      Sentry.withScope(scope => {
        setExtrasIfAny(scope, properties);
        Sentry.captureException(exception);
      });
    } else {
      Sentry.withScope(scope => {
        setExtrasIfAny(scope, data);
        Sentry.captureMessage(message, "error");
      });
    }
  },
  warn: (message: string, data?: Data) => {
    console.warn(message, data);
    const shallCapture = updateLogMessageMapFor(message);
    if (!shallCapture) {
      return;
    }
    Sentry.withScope(scope => {
      setExtrasIfAny(scope, data);
      Sentry.captureMessage(message, "warning");
    });
  },
};
